.c-service {
  &-intro {
    padding-bottom: 105px;
    &-heading {
      &__logo {
        display: block;
      }
      &__txt {
        display: block;
        margin-top: 20px;
        font-weight: 500;
        font-size: 2rem;
        letter-spacing: 2px;
      }
    }
    &__exp {
      margin-top: 35px;
      font-weight: bold;
      font-size: 2.4rem;
      &--broad {
        @extend .c-service-intro__exp;
        line-height: 1.67;
      }
    }
    &-problem {
      margin-top: 75px;
      &__ttl {
        color: $color-sub;
        font-weight: bold;
        font-size: 2.8rem;
      }
      &__list {
        display: flex;
        margin: 40px 70px 0;
        font-weight: bold;
        font-size: 1.8rem;
        justify-content: space-between;
        align-items: center;
      }
      &__item {
        position: relative;
        width: 300px;
        flex-basis: 300px;
        padding: 55px 0;
        border: 4px solid $color-sub;
        border-radius: 8px;
        line-height: 1.67;
        &::after {
          position: absolute;
          bottom: -31px;
          left: 80px;
          width: 36px;
          height: 32px;
          background: url(../img/service/img_fukidashi_add.png) no-repeat;
          content: "";
        }
        &--single {
          @extend .c-service-intro-problem__item;
          padding: 48px 0;
        }
        &--twin {
          @extend .c-service-intro-problem__item;
          padding: 33px 0;
        }
        &--triple {
          @extend .c-service-intro-problem__item;
          padding: 18px 0;
        }
      }
    }
  }
  &-function {
    &-list {
      display: flex;
      margin: 45px 45px 0;
      justify-content: space-between;
      &__item {
        width: 270px;
        flex-basis: 270px;
        text-align: center;
      }
      &__txt {
        margin-top: 30px;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 1.56;
      }
    }
  }
  &-price {
    margin-top: 100px;
    &__inner {
      width: 960px;
      margin: 0 auto;
    }
    &__container {
      & + & {
        margin-top: 60px;
      }
    }
    &__detail {
      text-align: center;
      &--single {
        @extend .c-service-price__detail;
      }
      &--twin {
        @extend .c-service-price__detail;
      }
      &--triple {
        @extend .c-service-price__detail;
      }
      dt {
        color: $color-sub;
        font-weight: bold;
        font-size: 3.4rem;
        text-align: center;
      }
      dd {
        margin-top: 30px;
      }
    }
    &__multiPic {
      display: flex;
      justify-content: center;
      img + img {
        margin-left: 30px;
      }
    }
    &__notation {
      margin-top: 20px;
      font-weight: 500;
      font-size: 1.6rem;
      &--list {
        @extend .c-service-price__notation;
        li + li {
          margin-top: 15px;
        }
      }
    }
    &-btn {
      margin-top: 28px;
      &__link {
        display: block;
        margin: 0 auto;
        width: 233px;
        padding: 20px 0;
        background: $color-sub;
        font-weight: bold;
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }
  &-contact {
    padding: 80px 0 132px;
    &__heading {
      font-weight: bold;
      font-size: 2.8rem;
      letter-spacing: 2.24px;
      text-align: center;
    }
    &-box {
      display: flex;
      margin-top: 40px;
      padding: 44px 0;
      border: 4px solid $color-sub;
      justify-content: center;
      &__content {
        width: 50%;
        padding: 20px 0;
        text-align: center;
        &:nth-of-type(1) {
          border-right: 1px solid #dadada;
        }
      }
      &__heading {
        color: $color-sub;
        font-weight: bold;
        font-size: 3.4rem;
      }
      &__txt {
        margin-top: 20px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.75;
      }
      &-btn {
        margin-top: 30px;
        &__link {
          display: block;
          width: 194px;
          margin: 0 auto;
          padding: 20px 0 18px;
          background: $color-sub;
          color: #fff;
          font-weight: bold;
          font-size: 1.6rem;
          letter-spacing: 1.6px;
        }
      }
      &-tel {
        display: flex;
        justify-content: center;
        align-items: center;
        .c-service-contact-box-btn__link {
          margin: 0;
        }
        &__item {
          margin-left: 35px;
        }
        &__link {
          display: block;
          margin-bottom: 5px;
          color: $color-sub;
          font-weight: bold;
          font-size: 3.2rem;
        }
      }
    }
  }
}
