.p-detail {
  main {
    width: 800px;
    margin-top: 156px;
  }
  .ttl-primary {
    font-weight: normal;
  }
  article {
    margin: 100px auto;
    h1 {
      margin-top: 25px;
      color: $color-sub;
      font-weight: bold;
      font-size: 2.8rem;
      line-height: 1.43;
      letter-spacing: 2.24px;
    }
    h2 {
      margin-top: 30px;
      color: #333;
      font-weight: bold;
      font-size: 2.2rem;
      line-height: 1.43;
      letter-spacing: 0.7px;
      & + p {
        margin-top: 10px;
      }
    }
    p {
      display: flex;
      margin-top: 30px;
      color: #333;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.2;
      letter-spacing: 1.6px;
      flex-wrap: wrap;
      img {
        &:nth-of-type(n+3) {
          margin-top: 25px;
        }
      }
    }
    ol {
      margin-top: 12px;
      margin-left: 33px;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.74;
      letter-spacing: 1.6px;
    }
    ul {
      margin-top: 12px;
      margin-left: 30px;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.74;
      letter-spacing: 1.6px;
      list-style-type: disc;
    }
    h2, p, ol, ul {
      a {
        color: $color-sub;
        &:hover {
          opacity: .8;
        }
      }
    }
  }
  &__eyecatch {
    margin: 30px 0;
    text-align: center;
    img {
      max-width: 800px;
    }
  }
  &--case {
    @extend .p-detail;
    article {
      h1 {
        margin-top: 0;
        font-size: 3.2rem;
        letter-spacing: 2.56px;
      }
      h2 {
        margin-top: 10px;
        font-weight: bold;
        font-size: 2.8rem;
        letter-spacing: 2.24px;
      }
      .p-detail {
        &__tag {
          display: flex;
          margin: 24px 0 0;
          justify-content: flex-start;
          li {
            max-width: 129px;
            flex-basis: 129px;
            padding: 1px 0;
            border-radius: 12px;
            background: $color-sub;
            color: #fff;
            font-weight: bold;
            font-size: 1.3rem;
            letter-spacing: 0.52px;
            list-style-type: none;
            text-align: center;
          }
          li + li {
            margin-left: 15px;
          }
        }
      }
      .c-btn-toIndex {
        margin-top: 40px;
      }
    }
  }
}
