.p-reserve {
  margin-top: 170px;
  &__contents {
    padding: 100px 0;
  }
  &-detail {
    margin-top: 100px;
    &__container {
      & + & {
        margin-top: 80px;
        padding-top: 80px;
        border-top: 1px solid #dadada;
      }
    }
    &__catchExp {
      font-weight: bold;
      font-size: 2rem;
      line-height: 1.6;
      letter-spacing: 1.6px;
      text-align: center;
    }
    &__pic {
      margin-top: 30px;
    }
    &-steps {
      display: flex;
      margin: 60px 30px 0;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        display: flex;
        width: 490px;
        flex-basis: 490px;
        justify-content: space-between;
        &:nth-of-type(n+3) {
          margin-top: 60px;
        }
      }
      &__ico {
        margin-right: 40px;
      }
      &-point {
        width: 390px;
        flex-basis: 390px;
        &__name {
          color: $color-sub;
          font-weight: bold;
          font-size: 3.4rem;
        }
        &__txt {
          margin-top: 15px;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.75;
        }
      }
    }
  }
}
