.pagination {
  margin: 60px 0 0;
  &-list {
    display: flex;
    justify-content: center;
    &__number {
      width: 32px;
      margin-right: 20px;
      font-weight: 500;
      font-size: 2rem;
      text-align: center;
      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
    &__link {
      display: block;
      width: 100%;
      padding: 6px 0 5px;
      border: 1px solid $color-sub;
      color: $color-sub;
      &:hover {
        background: $color-sub;
        color: #fff;
        transition: $transition;
        &.pagination-list__link--next::before {
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
      @at-root .is-active & {
        background: $color-sub;
        color: #fff;
      }
      &--next {
        position: relative;
        padding: 15px;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 9px;
          height: 9px;
          border-top: 2px solid $color-sub;
          border-right: 2px solid $color-sub;
          transform: translate(-70%, -50%) rotate(45deg);
          content: "";
        }
      }
    }
    &__ieFix {
      @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
        display: block;
        margin-bottom: -4px;
        padding-top: 4px;
      }
    }
  }
}

.page-numbers {
  display: flex;
  margin: 60px 0 100px;
  justify-content: center;
  span {
    width: 32px;
    margin: 0 10px;
    padding: 6px 0 5px;
    border: 1px solid $color-sub;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    &.current {
      background: $color-sub;
      color: #fff;
    }
    @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
      display: block;
      margin-bottom: -4px;
      padding-top: 10px;
    }
  }
  a {
    width: 32px;
    margin: 0 10px;
    padding: 6px 0 5px;
    border: 1px solid $color-sub;
    color: $color-sub;
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    // &:nth-last-of-type(1) {
    //   margin-right: 0;
    // }
    &:hover {
      background: $color-sub;
      color: #fff;
      transition: $transition;
      &.next::before {
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
    @media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
      display: block;
      margin-bottom: -4px;
      padding-top: 10px;
    }
  }
  .prev {
    @include pagination_arrow;
    &::before {
      border-top: 2px solid $color-sub;
      border-left: 2px solid $color-sub;
      transform: translate(-25%, -50%) rotate(-45deg);
      content: "";
    }
  }
  .next {
    @include pagination_arrow;
    &::before {
      border-top: 2px solid $color-sub;
      border-right: 2px solid $color-sub;
      transform: translate(-70%, -50%) rotate(45deg);
      content: "";
    }
  }
}
