$font-family-default: "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "メイリオ", sans-serif;

//color
$color-main: #333;
$color-sub: #40bad0;

$color-red: #f91515;
$color-gray: #c1c1c1;

$transition: .3s;
