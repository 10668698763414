.media {
  margin-top: 156px;
  margin-bottom: 100px;
  &-container {
    margin-top: 100px;
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-card {
    max-width: 340px;
    flex-basis: 340px;
    margin-right: 40px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: 40px;
    }
    &__link {
      display: block;
      width: 100%;
      padding: 10px 10px 20px;
      border: 1px solid #dadada;
      &:hover {
        border: 1px solid $color-sub;
        .media-card__ttl {
          color: $color-sub;
        }
      }
    }
    &__date {
      margin-top: 20px;
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }
    &__ttl {
      margin-top: 10px;
      color: $color-main;
      font-weight: bold;
      font-size: 2rem;
      line-height: 1.75;
    }
  }
}
