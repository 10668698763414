.p-delivery {
  margin-top: 170px;
  &__contents {
    // padding: 100px 0 28px;
    padding: 100px 0;
  }
  &-detail {
    margin-top: 100px;
    &__container {
      & + & {
        margin-top: 80px;
        padding-top: 80px;
        border-top: 1px solid #dadada;
      }
    }
  }
  &-catch {
    margin-top: 40px;
    text-align: center;
    &__txt {
      display: block;
      margin-top: 10px;
      color: $color-sub;
      font-weight: bold;
      font-size: 3.4rem;
      &--sm {
        display: block;
        margin-top: 10px;
        font-weight: bold;
        font-size: 1.6rem;
      }
    }
  }
  &__catchExp {
    margin-top: 14px;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 1.6px;
    text-align: center;
  }
  &-column {
    display: flex;
    margin-top: 55px;
    justify-content: space-between;
    align-items: center;
    & + & {
      margin-top: 80px;
    }
    &-desc {
      width: 480px;
      flex-basis: 480px;
      &__ttl {
        color: $color-sub;
        font-weight: bold;
        font-size: 3.4rem;
      }
      &__list {
        margin-top: 30px;
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 1.78;
        li + li {
          margin-top: 5px;
        }
      }
      &-btn {
        margin: 35px 0 0 5px;
        &__link {
          display: flex;
          width: 233px;
          padding: 20px 0;
          background: $color-sub;
          justify-content: center;
          align-items: center;
        }
        &__txt {
          margin-right: 10px;
          color: #fff;
          font-weight: bold;
          font-size: 1.6rem;
          letter-spacing: 1.6px;
        }
      }
    }
    &__pic {
      width: 570px;
      flex-basis: 570px;
    }
  }
}
