@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700');

html {
  background: #FFF;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  color: $color-main;
  font-weight: 500;
  font-size: 1.4rem;
  font-family: $font-family-default;
  line-height: 1.0;
}

main {
  width: 1100px;
  margin: 0 auto;
}

.inner {
  width: 1100px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}

svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
}

::selection {
  background-color: #5dc9dd;
  color: #fff;
}
::-moz-selection {
  background-color: #5dc9dd;
  color: #fff;
}
