.p-notfound {
  margin-top: 156px;
  text-align: center;
  &__ttl {
    color: $color-sub;
    font-weight: bold;
    font-size: 10rem;
    &--en {
      @extend .p-notfound__ttl;
      font-size: 2.8rem;
    }
  }
  &__txt {
    &--lg {
      margin-top: 40px;
      font-weight: bold;
      font-size: 2.8rem;
    }
    &--sm {
      margin-top: 20px;
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
}
