footer {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  min-width: 1100px;
  padding: 10px 0;
  background: #363c3d;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
}
