.c-btn {
  &-toIndex {
    margin-top: 40px;
    &__name {
      padding: 20px 50px;
      font-weight: bold;
      font-size: 1.6rem;
      letter-spacing: 1.6px;
    }
  }
  &_animation {
    &_skew {
      @include btn_animation_skew;
      &--whiteBack {
        @include btn_animation_skew--whiteBack;
      }
    }
  }
}

.btn {
  &-nav {
    position: relative;
    display: inline-block;
    text-decoration: none;
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      transform: scale(0, 1);
      transform-origin: right top;
      width: 100%;
      height: 2px;
      background: #40bad0;
      content: "";
      transition: transform .3s;
      @at-root .is-active & {
        position: absolute;
        bottom: 17px;
        left: 0;
        transform: inherit;
        width: 100%;
        height: 2px;
        background: #40bad0;
        content: "";
        &:hover{
          &::after {
            transform: unset;
          }
        }
      }
    }
    &:hover{
      &::after {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }
}
