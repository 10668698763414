.header {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  min-width: 1100px;
  background: rgba(255, 255, 255, 0.9);
  justify-content: space-between;
  align-items: center;
  &__heading {
    margin-left: 40px;
  }
  &__navs {
    display: flex;
    height: 70px;
    letter-spacing: 2px;
    align-items: center;
  }
  &__nav {
    margin-left: 20px;
    &:last-of-type {
      margin-left: 30px !important;
    }
    &--noLink {
      display: block;
      background: $color-sub;
    }
  }
  &-nav {
    &__link {
      padding: 27px 10px;
      color: #333;
      font-size: 1.6rem;
    }
    &__login {
      width: 184px;
      padding: 27px 0;
      color: #fff;
      font-size: 1.6rem;
      text-align: center;
    }
  }
  &__serviceTrigger, &__loginTrigger {
    position: relative;
  }
  &-triggeredLinks {
    display: none;
    text-align: center;
    &--service, &--login {
      @extend .header-triggeredLinks;
    }
    &__link {
      position: absolute;
      overflow: hidden;
      z-index: 1;
      bottom: -74px;
      left: -75%;
      width: 235px;
      padding: 18px 0;
      background: $color-sub;
      color: #fff;
      font-weight: bold;
      font-size: 1.4rem;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform-origin: right top;
        transform: skewX(-30deg) scale(0, 1);
        width: 130%;
        height: 100%;
        background: #369cae;
        content: "";
        transition: transform .4s;
      }
      &:hover {
        color: #fff;
        &::after {
          transform-origin: left top;
          transform: skewX(-30deg) scale(1, 1);
        }
      }
      &--delivery {
        @extend .header-triggeredLinks__link;
      }
      &--reserve {
        @extend .header-triggeredLinks__link;
        bottom: -148px;
        border-top: 1px solid #a0dde8;
      }
      &--deliveryLogin {
        @extend .header-triggeredLinks__link;
        bottom: -69px;
        left: 0;
        width: 184px;
        padding: 0;
        border-top: 1px solid #fff;
        font-weight: 500;
      }
      &--reserveRegist {
        @extend .header-triggeredLinks__link;
        bottom: -140px;
        left: 0;
        width: 184px;
        padding: 0;
        font-weight: 500;
        border-top: 1px solid #a0dde8;
        .header-nav__login {
          padding: 15px 0;
        }
      }
      &--reserveCenter {
        @extend .header-triggeredLinks__link;
        bottom: -211px;
        left: 0;
        width: 184px;
        padding: 0;
        font-weight: 500;
        border-top: 1px solid #a0dde8;
        .header-nav__login {
          padding: 15px 0;
        }
      }
      span {
        display: block;
        margin-top: 8px;
        font-weight: bold;
        font-size: 1.6rem;
      }
    }
  }
}

.header__nav {
  .btn {
    &-nav {
      position: relative;
      display: inline-block;
      text-decoration: none;
      &::after {
        position: absolute;
        bottom: 17px;
        left: 0;
        transform: scale(0, 1);
        transform-origin: right top;
        width: 100%;
        height: 2px;
        background: #40bad0;
        content: "";
        transition: transform .3s;
        @at-root .is-active & {
          position: absolute;
          bottom: 17px;
          left: 0;
          transform: inherit;
          width: 100%;
          height: 2px;
          background: #40bad0;
          content: "";
          &:hover{
            &::after {
              transform: unset;
            }
          }
        }
      }
      &:hover{
        &::after {
          transform-origin: left top;
          transform: scale(1, 1);
        }
      }
    }
  }
}

.is-active .btn-nav::after {
  position: absolute;
  bottom: 17px;
  left: 0;
  -webkit-transform: inherit;
  transform: inherit;
  width: 100%;
  height: 2px;
  background: #40bad0;
  content: "";
}
