.message {
  margin-top: 156px;
  &__txt {
    margin-top: 100px;
    text-align: center;
  }
}

.president {
  margin: 110px 0 120px;
  &__txts {
    position: relative;
    width: 590px;
    padding: 41px;
    background: #40bad0;
    color: #fff;
  }
  &__txt {
    font-size: 1.6rem;
    line-height: 2.25;
    letter-spacing: 1.6px;
  }
  &__name {
    margin-top: 25px;
    letter-spacing: 2px;
  }
  &-name {
    &__post {
      font-size: 1.6rem;
    }
    &__lg {
      position: relative;
      margin-top: 10px;
      font-weight: bold;
      font-size: 2.8rem;
      &:after {
        position: absolute;
        bottom: 0;
        left: 172px;
        font-weight: normal;
        font-size: 1.8rem;
        content: "Yuto Harukiya";
      }
    }
  }
  &__pic {
    position: relative;
    &--inner {
      position: absolute;
      right: 0;
      bottom: 50px;
      z-index: -1;
      border: 5px solid #40bad0;
    }
  }
}
