.download {
  margin-top: 156px;
  margin-bottom: 100px;
  &__progressBar {
    margin: 0 auto;
    text-align: center;
    img {
      margin-left: 45px;
    }
  }
  &__content {
    width: 960px;
    margin: 0 auto;
    padding-bottom: 132px;
    &--confirm {
      @extend .download__content;
      margin-top: 78px;
    }
    &--finish {
      @extend .download__content;
      margin-top: 100px;
    }
  }
}

.contact {
  margin-top: 156px;
  margin-bottom: 100px;
  &__progressBar {
    margin: 0 auto;
    text-align: center;
  }
}
