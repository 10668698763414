@mixin placeholders {
  &:placeholder-shown {
    color: $color-gray;
  }
  &::-webkit-input-placeholder {
    color: $color-gray;
  }
  &:-ms-input-placeholder {
    color: $color-gray;
  }
}

@mixin pagination_arrow {
  position: relative;
  padding: 15px;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
    content: "";
  }
}

@mixin btn_animation_skew {
    display: inline-block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: #40bad0;
    color: #fff;
    text-decoration: none;
    transition: .5s;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transform-origin: right top;
      transform: skewX(-30deg) scale(0, 1);
      width: 130%;
      height: 100%;
      background: #369cae;
      content: "";
      transition: transform .4s;
    }
    &:hover {
      color: #fff;
      &::after {
        transform-origin: left top;
        transform: skewX(-30deg) scale(1, 1);
      }
    }
}

@mixin btn_animation_skew--whiteBack {
  @include btn_animation_skew;
  background: #fff;
  &::after {
    background: #40bad0;
  }
}
