.service {
  margin-top: 156px;
  &__scope {
    margin-top: 100px;
  }
  &-scope {
    &__pic {
      margin-top: 40px;
    }
  }
}

.various {
  margin: 100px 0;
  &__services {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-services {
    &__item {
      text-align: center;
      &:nth-of-type(n+3) {
        margin-top: 60px;
      }
      dt {
        margin-top: 30px;
        font-weight: bold;
        font-size: 2.8rem;
        letter-spacing: 1.4px;
      }
      dd {
        margin-top: 20px;
        font-size: 1.6rem;
      }
    }
  }
}
