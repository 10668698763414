.font {
  &-bold {
    font-weight: bold;
  }
}

.u-font {
  &-subcolor {
    color: $color-sub;
  }
}

.u-indentation {
  padding-left: 1em;
  text-indent: -1em;
}
