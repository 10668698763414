.main {
  &-theme {
    position: absolute;
    top: 50%;
    left: 50%;
    transform : translate(-50%,-50%);
  }
}

.news {
  width: 100%;
  min-width: 1100px;
  padding: 60px 0 100px 0;
  font-size: 1.6rem;
  text-align: center;
  list-style: none;
  &__ttl {
    color: #40bad0;
    font-weight: 500;
    font-size: 3.8rem;
    letter-spacing: 1.52px;
    &--lower {
      display: block;
      margin-top: 10px;
      color: #000;
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: normal;
    }
  }
  &__items {
    width: 960px;
    margin: 40px auto 0;
    list-style-type: none;
  }
  &-item {
    border-top: 1px solid #dadada;
    line-height: 1.6;
    letter-spacing: 1.6px;
    text-align: left;
    &:nth-last-of-type(1) {
      border-bottom: 1px solid #dadada;
    }
    &__link {
      display: block;
      width: 100%;
      padding: 30px 0 25px;
      color: #333;
      &:hover {
        color: #40bad0;
        transition: .2s;
      }
    }
    &__noLink {
      display: block;
      width: 100%;
      padding: 30px 0 25px;
      color: #333;
    }
    &__date {
      margin-right: 15px;
      color: #999;
      font-weight: bold;
    }
    &__label {
      padding: 4px 10px 3px;
      color: #fff;
      font-size: 1.3rem;
      &--notice {
        @extend .news-item__label;
        background: $color-sub;
      }
      &--med {
        @extend .news-item__label;
        background: #458cce;
      }
      &--serv {
        @extend .news-item__label;
        background: #52bca7;
      }
      &--comp {
        @extend .news-item__label;
        background: #457396;
      }
      &--others {
        @extend .news-item__label;
        padding: 4px 17px;
        background: #d25252;
      }
    }
    &__ttl {
      display: block;
      width: 100%;
      margin-top: 10px;
      font-weight: bold;
      cursor: default;
    }
    &__note {
      width: 100%;
      line-height: 1.8;
      &--noTitle {
        margin-top: 10px;
      }
    }
  }
}
