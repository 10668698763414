.u {
  &-link {
    &-tel {
      cursor: default;
      pointer-events: none;
      @media screen and (max-width: 1100px) {
        pointer-events: auto;
      }
    }
  }
}
