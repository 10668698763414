.c-form {
  &-switcher {
    width: 960px;
    margin: 80px auto 54px;
    &__ttl {
      font-weight: bold;
      font-size: 2.2rem;
      letter-spacing: 1.76px;
    }
    &__list {
      display: flex;
      margin-top: 30px;
      list-style-type: none;
      li + li {
        margin-left: 40px;
      }
    }
    &__input {
      display: none;
      & + .c-form-switcher__label {
        position: relative;
        padding-left: 32px;
        cursor: pointer;
      }
      & + .c-form-switcher__label::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 2px solid $color-sub;
        border-radius: 50%;
        content: "";
      }
      &:checked + .c-form-switcher__label::after {
        display: block;
        position: absolute;
        top: 6px;
        left: 6px;
        width: 10px;
        height: 10px;
        background: $color-sub;
        border-radius: 50%;
        content: "";
      }
    }
    &__label {
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 1.44px;
    }
  }
  &-globalError {
    margin-bottom: 32px;
    color: $color-red;
    font-weight: bold;
    font-size: 2.2rem;
    letter-spacing: 1.76px;
  }
  &__notation {
    display: flex;
    margin-left: -5px;
    font-weight: bold;
    font-size: 1.6rem;
    align-items: center;
  }
  &-table {
    & + & {
      margin-top: 50px;
    }
    &:first-of-type {
      margin-top: 24px;
    }
    &:last-of-type {
      margin-bottom: 60px;
    }
    &.is-disabled {
      display: none;
    }
    &__arrow {
      position: relative;
      &::after {
        position: absolute;
        top: 24px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 8px 0 8px;
        border-color: #000333 transparent transparent transparent;
        content: "";
      }
    }
    &__name {
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 1.44px;
    }
    &__data {
      margin-top: 12px;
      input {
        width: 100%;
        height: 58px;
        padding: 0 20px;
        border: 1px solid #dadada;
        color: #333;
        font-size: 1.8rem;
        letter-spacing: 1.44px;
        &.is-required {
          border: 1px solid $color-red;
        }
        @include placeholders;
      }
      textarea {
        width: 100%;
        height: 200px;
        padding: 20px;
        border: 1px solid #dadada;
        font-size: 1.8rem;
        letter-spacing: 1.44px;
        font-family: $font-family-default;
        &.is-required {
          border: 1px solid $color-red;
        }
        @include placeholders;
        &:placeholder-shown {
          font-family: Arial, sans-serif;
        }
      }
      select {
        width: 100%;
        height: 58px;
        padding: 0 18px;
        border: 1px solid #dadada;
        color: #333;
        font-weight: bold;
        font-size: 1.8rem;
        letter-spacing: 1.44px;
        font-family: $font-family-default;
        appearance: none;
        &.is-required {
          border: 1px solid $color-red;
        }
        &::-ms-expand {
          display: none;
        }
      }
    }
  }
  &-btn {
    text-align: center;
    &__item {
      padding: 20px 47px;
      border: none;
      font-weight: bold;
      font-size: 1.6rem;
      letter-spacing: 1.6px;
      cursor: pointer;
    }
  }
  &__asterisk {
    color: $color-red;
    font-weight: bold;
    font-size: 1.4rem;
    vertical-align: top;
    &--lg {
      color: $color-red;
      font-weight: bold;
      font-size: 2.4rem;
    }
  }
  &__error {
    margin-top: 8px;
    color: $color-red;
    font-weight: bold;
    font-size: 1.4rem;
  }
}

.c-confirm {
  &__topMessage {
    font-weight: bold;
    font-size: 2.2rem;
    letter-spacing: 1.76px;
  }
  &-table {
    margin-top: 48px;
    font-size: 1.8rem;
    letter-spacing: 1.44px;
    &__name {
      font-weight: bold;
    }
    &__data {
      margin-top: 10px;
      line-height: 1.8;
    }
  }
  &-btn {
    display: flex;
    margin-top: 48px;
    justify-content: center;
    &__item {
      padding: 20px 47px;
      border: none;
      font-weight: bold;
      font-size: 1.6rem;
      letter-spacing: 1.6px;
      cursor: pointer;
      &--goBack {
        @extend .c-confirm-btn__item;
        margin-right: 40px;
        padding: 18px 47px;
        border: 2px solid $color-sub;
        background: #fff;
        color: $color-sub;
      }
    }
  }
}

.c-finish {
  &__topMessage {
    font-weight: bold;
    font-size: 3.2rem;
    letter-spacing: 2.56px;
    text-align: center;
  }
  &-download {
    margin-top: 56px;
    text-align: center;
    &__note {
      font-weight: bold;
      &--contact {
        width: 720px;
        margin: 0 auto;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2.25;
        letter-spacing: 1.28px;
        text-align: left;
      }
    }
    &-item {
      margin-top: 14px;
      &__link {
        padding: 22px 75px 22px 50px;
        font-weight: bold;
        font-size: 2rem;
        letter-spacing: 1.6px;
        cursor: pointer;
      }
      &__icon {
        position: relative;
        letter-spacing: 0.8px;
        &::after {
          position: absolute;
          top: 50%;
          right: -27px;
          transform: translateY(-50%);
          width: 16px;
          height: 19px;
          background: url(../img/download/svg_download.svg) no-repeat;
          content: "";
        }
      }
      &-goHome {
        margin-top: 57px;
        &__link {
          padding: 22px 49px;
          font-weight: bold;
          font-size: 1.6rem;
          letter-spacing: 1.6px;
          cursor: pointer;
        }
      }
    }
  }
}
