.company {
  margin-top: 156px;
  &__profile {
    margin-top: 100px;
  }
  &__table {
    width: 1100px;
    margin-top: 40px;
    font-size: 1.6rem;
    letter-spacing: 1.6px;
    tr:last-child {
      border-bottom: 1px solid #dadada;
    }
  }
  &-table {
    &__tr {
      border-top: 1px solid #dadada;
    }
    &__th {
      width: 160px;
      padding: 32px 0;
      font-weight: bold;
    }
    &__link {
      font-weight: bold;
      color: $color-sub;
      &:hover {
        text-decoration: underline;
      }
      &--message {
        @extend .company-table__link;
      }
    }
  }
  &-th {
    &__equaler {
      padding: 32px 0 60px;
    }
  }
  &-td {
    &__lists {
      line-height: 1.875;
    }
    &__equaler {
      padding: 32px 0;
    }
  }
}

.access {
  margin: 100px 0;
  &__map {
    width: 100%;
    margin-top: 40px;
  }
  &-map {
    &__iframe {
      position: relative;
      right: 50%;
      left: 50%;
      width: 100vw;
      height: 500px;
      margin-right: -50vw;
      margin-left: -50vw;
    }
    &__link {
      width: 240px;
      height: 55px;
      margin: 40px auto 0;
    }
  }
}

.map {
  &-link {
    &--lg {
      padding: 20px 50px;
      font-size: 1.6rem;
      text-align: center;
    }
    &__ico {
      display: inline-block;
      width: 13px;
      height: 15px;
      vertical-align: top;
      fill: #fff;
    }
    &__txt {
      display: inline-block;
      color: #fff;
      vertical-align: top;
    }
  }
}
