.ttl {
  &-main {
    width: 672px;
    color: #000;
    font-weight: bold;
    font-size: 4.8rem;
    line-height: 1.83;
    font-family: "Noto Sans JP", sans-serif;
    letter-spacing: 7px;
    text-align: center;
    &--blue {
      color: #40bad0;
    }
  }
  &-primary {
    color: #40bad0;
    font-size: 4.4rem;
    font-family: "Noto Sans JP", sans-serif;
    letter-spacing: 1.76px;
    text-align: center;
  }
  &-secondary {
    position: relative;
    margin-left: 50px;
    font-size: 2.8rem;
    font-family: "Noto Sans JP", sans-serif;
    letter-spacing: 1.12px;
    &:before {
      position: absolute;
      top: 50%;
      left: -50px;
      width: 40px;
      height: 2px;
      margin-top: -1px;
      background: #40bad0;
      content: "";
    }
  }
}
