.ta {
  &-r {
    text-align: right;
  }
  &-c {
    text-align: center;
  }
  &-l {
    text-align: left;
  }
}

.u-ta {
  &-r {
    text-align: right;
  }
  &-c {
    text-align: center;
  }
  &-l {
    text-align: left;
  }
}

.u-mt {
  &-0 {
    margin-top: 0 !important;
  }
  &-30 {
    margin-top: 30px !important;
  }
  &-40 {
    margin-top: 40px !important;
  }
}
