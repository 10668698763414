.case {
  margin-top: 156px;
  margin-bottom: 100px;
  &-container {
    margin-top: 100px;
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-card {
    max-width: 340px;
    flex-basis: 340px;
    margin-right: 40px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(n+4) {
      margin-top: 40px;
    }
    &__link {
      display: block;
      width: 100%;
      border: 1px solid #dadada;
      &:hover {
        border: 1px solid $color-sub;
        .case-card-box__ttl, .case-card-box__name {
          color: $color-sub;
        }
      }
    }
    &-box {
      padding: 16px 20px 20px;
      &__ttl {
        color: $color-main;
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 1.38;
      }
      &__name {
        margin-top: 10px;
        color: $color-main;
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.5;
      }
      &__tag {
        display: flex;
        margin-top: 23px;
        li {
          max-width: 129px;
          flex-basis: 129px;
          padding: 6px 0;
          border-radius: 12px;
          background: $color-sub;
          color: #fff;
          font-weight: bold;
          font-size: 1.3rem;
          letter-spacing: 0.52px;
          text-align: center;
          justify-content: flex-start;
        }
        li + li {
          margin-left: 15px;
        }
      }
    }
  }
}
