.p-news {
  &-main {
    margin-top: 156px;
  }
  &-container {
    margin: 100px auto;
    .news__items {
      width: 1100px;
      margin: 33px auto 0;
      font-size: 1.6rem;
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ttl-secondary + .ttl-secondary {
        display: none;
      }
    }
  }
  &-selector {
      position: relative;
      &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-width: 8px 6.5px 0 6.5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
      content: "";
    }
    &__btn {
      width: 132px;
      max-width: 132px;
      flex-basis: 132px;
      height: 40px;
      padding-left: 20px;
      border: 1px solid #dadada;
      color: #333;
      font-size: 1.6rem;
      letter-spacing: 1.6px;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
      option {
        font-size: 1.6rem;
        font-family: $font-family-default;
      }
    }
  }
}
